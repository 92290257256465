import logo from "./logo.png";
import "./App.css";

function App() {
  return (
    <div className="App">
      <header className="App-header">
        <img src={logo} className="App-logo" alt="logo" />

        <p>
          <h1>Introduction</h1>
          <p>
            Thank you for visiting our website. The team at GTP Keyboard
            respects your right to privacy. The purpose of this website privacy
            policy is to outline how we deal with any personal data you provide
            to us while visiting this website. Naturally, if you are not happy
            with our website privacy policy then you should not use this
            website.
          </p>
          <p>
            By visiting this website, you are accepting the terms of this
            website privacy policy. Any external links to other websites are
            clearly identifiable as such, and we are not responsible for the
            content or the privacy policies of these websites.
          </p>
          <p>
            These practices may be changed, but any changes will be posted and
            changes will only apply to activities and information on a going
            forward, not retroactive basis. You are encouraged to review our
            website privacy policy whenever you visit the site to make sure that
            you understand how any personal information you provide will be
            used.
          </p>
          <h2>Types of Information Collected and How We Use It</h2>
          <p>We retain two types of information:</p>
          <ol>
            <li>Personal Data</li>
            <li>Non-personal Data</li>
          </ol>
          <p>How we use those data is described in the next two paragraphs.</p>
          <h3>Personal Data</h3>
          <p>
            This is data that identifies you or can be used to identify or
            contact you and may include your (e.g. name, address, telephone
            number, email address, user IP addresses in circumstances where they
            have not been deleted, clipped or made anonymous, telephone number,
            birth date and billing and credit card information). When you
            subscribe to content on GTP Keyboard, we ask for information such as
            your name, email, address and telephone number. The team at GTP
            Keyboard uses collected information for the following general
            purposes: products and services provision, billing, identification
            and authentication, services improvement, contact and research.
          </p>
          <h3>Non-Personal Data</h3>
          <p>
            Like most websites, we gather statistical and other analytical
            information collected on an aggregate basis of all visitors to our
            website. This Non-Personal Data is comprised of information that
            cannot be used to identify or contact you (e.g., demographic
            information regarding user IP addresses where they have been clipped
            or made anonymous, browser types and other anonymous statistical
            data involving the use of our website).
          </p>
          <h2>Cookie and Tracking Technologies</h2>
          <p>
            GTP Keyboard may use cookie and tracking technologies depending on
            the features offered. Cookie and tracking technologies are useful
            for gathering information such as logging users’ browser type and
            operating system, tracking the number of visitors to the site, and
            understanding how visitors use the site.
          </p>
          <p>
            Cookies can also help customize the site for visitors. Personal
            information cannot be collected via cookies and other tracking
            technologies. However, if you previously provided personally
            identifiable information, cookies may be tied to such information.
            Aggregate cookie and tracking information may be shared with third
            parties after these third parties have signed a confidentiality
            agreement approved by the GTP Keyboard team.
          </p>

          <h2>Distribution of Information</h2>
          <p>
            The information we collect is used to improve the content of our web
            pages and the quality of our service, and is not shared with or sold
            to other organizations for commercial purposes, except to provide
            products or services you’ve requested, when we have your permission.
          </p>
          <p>
            We may share information with governmental agencies or other
            companies assisting us in fraud prevention or investigation. We may
            do so when we are permitted or required by law, or when trying to
            protect against or prevent actual or potential fraud or unauthorized
            transactions, when investigating fraud which has already taken
            place.
          </p>
          <h2>Commitment to Data Security</h2>
          <p>
            Your personally identifiable information is kept secure. Only
            authorized employees, agents and contractors (who have agreed in
            writing to keep information secure and confidential) have access to
            this information. The GTP Keyboard team uses third party vendors and
            hosting partners to provide the necessary hardware, software,
            networking, storage, and related technology required to run.
            Although the GTP Keyboard team owns the code, databases and all
            rights to the application, you retain all rights to your data. You
            may ask us to share back with you the personal data you have given
            us. You may also ask us to delete your personal data from our files.
          </p>
          <p>
            Although GTP Keyboard has implemented strict security policies,
            rules, procedures and technical measures to protect your personal
            data, we cannot guarantee the security of the data that you transmit
            through the website, and any transmission is at your own risk and
            the companies and employees which run GTP Keyboard (including but
            not limited to its creator, Target Directories Corporation) will not
            be held liable for adverse consequences that result from such a
            security breach.
          </p>

          <h2>Sale of Business</h2>
          <p>
            We reserve the right to transfer information (including your
            personal data) to a third party in the event of a sale, merger,
            liquidation, receivership or transfer of all or substantially all of
            the assets of our company provided that the third party agrees to
            adhere to the terms of the website privacy policy and provided that
            the third party only uses your Personal Data for the purposes that
            you provided it to us. You will be notified in the event of any such
            transfer and you will be afforded an opportunity to opt-out.
          </p>
          <h2>Changes to the Website Privacy Policy</h2>
          <p>
            Any changes to this website privacy policy will be posted on this
            website so you are always aware of what information we collect, how
            we use it, and under what circumstances, if any, we disclose it. If
            at any time we decide to use personal data in a manner significantly
            different from that stated in this website privacy policy, or
            otherwise disclosed to you at the time it was collected, we will
            notify you by email. You will have a choice as to whether or not we
            use your information in the new manner.
          </p>
          <h2>Your Rights</h2>
          <p>
            In accordance with our duties as data controllers we aim to process
            data fairly. As mentioned above, we are committed to keeping secure
            all personal data we collect. If you wish to find out what personal
            data GTP Keyboard holds on you, you can email our Compliance Manager
            at davidprice35@gmail.com. We will respond to this data access
            request as soon as possible and no longer than a reasonable amount
            of time.
          </p>
          <h2>Contact Us</h2>
          <p>
            If you have any other questions relating to privacy or data
            protection, please contact us by email at davidprice35@gmail.com.
          </p>

          <h2>Thank You</h2>
          <p>
            We thank you for visiting our website and for your careful reading
            of this website privacy policy.
          </p>
        </p>
      </header>
    </div>
  );
}

export default App;
